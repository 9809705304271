.multiselectContainer {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  height: 45px;
}
.date-picker {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  height: 45px;
  width: 215px;
}